<template>
    <div class="authenticate-panel grid xs-grid-cols-1 gap-3">
        <div class="text-center"  v-if="!isTriggerByPopSignIn">
            <a class="circle huge signin-header-logo"></a>
        </div>
        <div class="text-center" v-if="isTriggerByPopSignIn">
            <div class="f5 text-medium">Sign up for recommendations, homeowner tools, and to save homes and searches.</div>
        </div>
        <div class="text-center grid xs-grid-cols-1" v-else>
            <h3 class="f3 text-bold" data-role="title">{{getTitle}}</h3>
            <div class="f5 text-medium" v-html="getSubTitle"></div>
        </div>
        <form class="form form-default grid xs-grid-cols-1 gap-3" :class="glb.authFormState" v-submit="submit" novalidate>
            <mvtTextbox  v-if="isForcedSignup">
                <div class="input">
                    <label>{{ molang('search.common.name') }}</label>
                    <input
                        ref="name"
                        type="text"
                        name="name"
                        v-inputtrim
                        v-model="name"
                        maxlength="128"
                        v-validate="'required|name'"
                        :autocomplete="isACForm ? 'off': 'name'"
                        placeholder="Full Name"
                    />
                </div>
            </mvtTextbox>
            <mvtTextbox>
                <div class="input">
                    <label>Email</label>
                    <input type="email" name="email" v-model="email" v-validate="'required|email'"/>
                </div>
            </mvtTextbox>
             <mvtTextbox v-if="isForcedSignup">
                <span class="textbox-tip label f6 text-safe text-bold"><i class="icon-shield-check"></i> Connect with a top local agent</span>
                <div class="input">
                    <label>{{ molang('search.common.MobilePhone') }} (Optional)</label>
                    <input
                        ref="phone"
                        type="tel"
                        name="phone"
                        v-model="phone"
                        v-inputformat:phone
                        v-validate="'phoneNumber'"
                        maxlength="12"
                        placeholder="123-123-1234"
                    />
                </div>
            </mvtTextbox>
            <div v-if="glb.authErrorMessage" class="text-error f7" v-html="glb.authErrorMessage"></div>
            <button class="btn primary large continuerWithEmail" type="submit">{{ctaText}} <span v-if="!isForcedSignup">with Email</span></button>

            <div class="login-alternate" v-if="!showOnlyEmail">
                <hr>or<hr>
            </div>
            <mvtSocial v-if="!showOnlyEmail" :trigger="options.trigger" :requestName="options.requestName"/>
            <template v-if="!showOnlyEmail || isForcedSignup">
                <div class="f8 text-a text-center" v-html="molang('dpp.mvtEmail.signupTerms')"></div>
            </template>
        </form>
    </div>
</template>
<script src="./mvt-login.js"></script>