<template>
    <div class="authenticate-panel grid xs-grid-cols-1 gap-3">
        <template v-if="!isUserExist">
            <div class="text-center">
                <i class="circle huge signin-header-logo"></i>
            </div>
            <div class="text-center">
                <h3 class="f3 text-bold">Welcome Back </h3>
                <div class="f5 text-medium">Sign in to access your account.</div>
            </div>
        </template>
        <template v-else >
            <div class="dialog-profile">
                <i class="circle  toggle  huge icon-shield-check"></i>
            </div>
            <div class="text-center">
                <h3 class="f3 text-bold">Enter Password </h3>
            </div>
        </template>
        <form class="form form-default grid xs-grid-cols-1 gap-3" :class="formState" v-submit="submit" novalidate>
            <mvtTextbox v-if="!isUserExist">
                <div class="input">
                    <label>Email</label>
                    <input type="email" name="username" v-model="username" v-validate="'required|email'"/>
                </div>
            </mvtTextbox>
            <mvtTextbox>
                <div class="input">
                    <label>Password</label>
                    <input type="password" data-dd-privacy="mask-user-input" ref="password" name="password" v-model="password" v-validate="'required'"/>
                </div>
            </mvtTextbox>
            <div v-if="errorMsg" class="text-error f7" v-html="errorMsg"></div>
            <div class="flex">
                <a class="link"  @click="switchComponent('mvtForgetPassword')">Forgot Password?</a>
            </div>
            <button class="btn primary large" type="submit" >Continue with Email</button>
            <template v-if="!isUserExist">
                <div class="login-alternate">
                    <hr>or<hr>
                </div>
                <mvtSocial :trigger="options.trigger" :requestName="options.requestName"/>
            </template>
        </form>
    </div>
</template>
<script src="./mvt-emaillogin.js"></script>