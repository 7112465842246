import mvtTextbox from '@/common/components/mvttextbox/mvt-textbox.vue';
import mvtSocial from '@/common/components/mvtsocial/mvt-social.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'mvtEmailLogin',
    components: {
        mvtTextbox,
        mvtSocial,
    },
    props: {
        options: {
            type: Object,
            default: {
                trigger: '',
                errorMessage: '',
                email: '',
                isUserExist: false
            }
        }
    },
    inject: ['$eventBus'],
    data() {
        return {
            errorMsg: '',
            formState: '',
            username: this.options && this.options.email || '',
            password: '',
            showPassword: false,
            phone: this.options && this.options.phone || '',
            name: this.options && this.options.name || '',
        };
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getErrorHtml','getSplit']),
        isUserExist() {
            return this.options.isUserExist;
        },
    },
    mounted() {
        $(this.$el).on('focus', 'input', () => {
            this.errorMsg = '';
        });
    },
    methods: {
        ...mapActions('glb', ['login']),
        switchComponent: function(name) {
            this.$emit('switchComponent', name);
        },
        onBack: function(){
            if(this.options && this.options.type === 'mvtWelcomeBack'){
                this.switchComponent('mvtWelcomeBack');
            } else {
                this.switchComponent('mvtLogin');
            }
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
            if(this.showPassword){
                this.$refs.password.type = 'text';
            } else{
                this.$refs.password.type = 'password';
            }
        },
        submit: function() {
            this.formState = 'submitting';
            this.login({
                username: this.username,
                password: this.password,
                trigger: this.options.trigger || this.$options.name,
                userType: this.glb.pageInfo.userType,
                phone: this.phone,
                name: this.name
            })
            .then(resp => {
                let status = resp && resp.status;
                if (status && status.code === 0) {
                    this.errorMsg = '';
                    this.formState = 'submitted';
                    this.$eventBus.$emit('eventbus-authenticated', this.options.requestName);
                    this.$eventBus.$emit('close-dialog', this.$options.name);
                } else {
                    this.formState = 'rejected';
                    this.errorMsg = this.getErrorHtml(status);
                }
            })
            .catch(err => {
                this.formState = '';
            });
        },
    },
};
