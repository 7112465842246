<template>
  <div ref="dialog" class="dialog" :class="{unclose: !autoClose}" v-if="isActive" :comp="componentName">
    <div class="dialog-overlay" @click="overlayClick"></div>
      <div class="dialog-panel">
        <div class="dialog-overlay-xs" @click="overlayClick"></div>
        <a ref="dialogclose" class="dialog-close" :comp="componentName" @click="close"></a>
        <div ref="dialogBody" class="dialog-body" >
            <div>
              <slot></slot>
            </div>
        </div>
      </div>
  </div>
</template>

<script src="./mvt-dialog.js"></script>
