<template>
    <div  class="authenticate-panel grid xs-grid-cols-1 gap-3">
        <div v-if="profile" class="dialog-profile">
            <i v-if="/^icon-/.test(profile)" class="circle  toggle  huge" :class="profile" />
            <img v-else :src="profile" class="circle  toggle  huge" />
        </div>
        <div class="text-center grid xs-grid-cols-1">
            <h3 v-if="formInfo.title" class="f3 text-bold" data-role="title" v-html="formInfo.title"></h3>
            <div v-if="formInfo.subTitle" class="f5" v-html="formInfo.subTitle"></div>
        </div>
        <form class="form form-default grid xs-grid-cols-1 gap-3" :class="glb.authFormState"  v-submit="submitSignup">
            <mvtTextbox>
                <div class="input">
                    <label>Email</label>
                    <input type="email" v-model="email"  v-validate="'required|email'" />
                </div>
            </mvtTextbox>
            <div v-if="glb.authErrorMessage" class="text-error">{{glb.authErrorMessage}}</div>
            <div class="f5" v-if="formInfo.showDisclaimer">
                {{molang('dpp.mvtEmail.disclaimer')}}
            </div>
            <button class="btn primary regular" type="submit" >{{formInfo.buttonText}}</button>
            <div class="login-alternate">
                <hr>or<hr>
            </div>
            <mvtSocial :requestName="options.requestName" :trigger="options.trigger"/>
            <div class="f8 text-a lh-normal" v-html="molang('dpp.mvtEmail.movotoTerms1')">
            </div>
        </form>
    </div>
</template>
<script src="./mvt-checkemail.js"></script>

<style lang="scss">
     @import "./mvt-checkemail.scss";
</style>