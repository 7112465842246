import { mapGetters, mapMutations, mapActions } from 'vuex';
import { getSaveSearchByPath, getPathBySearchObj } from '../../../../widget/spec/searchHelper';
import mvtSearchbox from '@/common/components/mvtsearchbox/mvt-searchbox.vue';
import searchFilters from '@/msp/mixins/searchfilters';
import mvtMapStatic from '@/common/components/mvtmapstatic/mvt-mapstatic.vue';
import mapper from '@/common/store/mapper/index.js';
import mvtHeaderPresentational from '@/common/components/mvtheader/mvt-header.presentational.vue';
import { defineAsyncComponent } from 'vue';
const isSSR = typeof window === 'undefined';

const propertyTypeMap = {
    'single-family': 'Homes',
    'multi-family': 'Multi-family homes',
    condos: 'Condos & townhouses',
    mobile: 'Mobile homes',
    land: 'Lots & land',
    other: 'Other property types',
};

//doto cw-4162:step3.5 redesign header model
export default {
    name: 'mvtHeader',
    root: true,
    inject: ['$eventBus'],
    props: ['searchInput', 'geoInfo', 'sticky', 'disableSearch', 'theme'],
    mixins: [searchFilters],
    components: {
        mvtSearchbox,
        mvtMapStatic,
        mvtHeaderPresentational,
    },
    computed: {
        ...mapGetters('glb', [
            'glb',
            'getOfficeInfo',
            'getSubscriptions',
            'getLastSearch',
            'getMenu',
            'getHeaderPhoneNumber',
            'getFeed',
            'getSavedHomesCount',
            'getSavedSearchesCount',
            'getSplit',
            'getAgent',
            'popLeadBeforeRedirect',
            'getNearbyHomesFromLatLng',
        ]),
        ...mapGetters('msp', ['msp', 'getSchoolRequest']),
        ...mapGetters('dpp', ['dpp']),
        hotleadProperty() {
            if (this.dpp && this.dpp.propertyId) {
                return this.dpp;
            } else if (this.msp && this.msp.firstProperty && this.msp.firstProperty.propertyId) {
                return this.msp.firstProperty;
            }
            return null;
        },
        geo() {
            return this.geoInfo || this.glb.geo || {};
        },
        isMapSearchPage() {
            return this.glb.pageType === 'vmapsearch' && this.$route.name === 'vmapsearch';
        },
        loginMenu() {
            var menus = [];
            if (this.glb.user.id) {
                if (['AGENT', 'COBROKER_AGENT'].indexOf(this.glb.user.role) >= 0) {
                    menus = [
                        {
                            text: this.molang('header.AgentHelp'),
                            href: 'https://agenthelp.movoto.com/',
                        },
                    ].concat(menus);
                }
                if (['AGENT', 'COBROKER_AGENT', 'NORMAL', 'MANAGEMENT'].indexOf(this.glb.user.role) >= 0) {
                    menus = [
                        {
                            text: this.molang('header.profile'),
                            href: this.glb.appUrl + 'my-movoto/account/',
                        },
                    ].concat(menus);
                }
                menus = menus.concat([
                    {
                        text: this.molang('header.downloadApp'),
                        href: this.glb.appUrl + 'mobile/',
                    },
                    {
                        text: this.molang('header.SignOut'),
                        event: 'eventbus-signout',
                    },
                ]);
            }
            return menus;
        },
        getUserShortName() {
            let names = (this.glb.user.fullName || '').split(' ').filter(Boolean),
                shortName;

            if (names.length) {
                shortName = names[0][0] + (names.length > 1 ? names[names.length - 1][0] : '');
            }

            return shortName ? shortName.toUpperCase() : '';
        },
        getUserName() {
            return this.glb.user.fullName;
        },
        getSearchInput() {
            return this.searchInput;
        },
        getNotificationCount() {
            return this.getSubscriptions ? this.getSubscriptions.length : 0;
        },
        getClaimedProperties() {
            return this.glb.user.id ? (this.glb.claimedPropertiesList && this.glb.claimedPropertiesList.length ? this.glb.claimedPropertiesList : 0) : 0;
        },
        hideMenuItemBySplit() {
            return !this.glb.disableLinkIndexing;
        },
    },
    methods: {
        ...mapActions('glb', ['getSavedSearches', 'getFavoriteListings', 'getHousesBySearchUrl', 'getClaimedHomes', 'addClaimedHome', 'addSaveSearch', 'updateFavorite', 'fetchNearbyHomesByLatLng']),
        ...mapMutations('glb', ['runToDoList', 'updateLastSearchinfo', 'updatePrevSearchinfo', 'addLandingPage', 'updateViewport', 'updateRecentSearchList']),
        getBaseDataForSaveSearch() {
            if (this.glb.pageType === 'vmapsearch' && this.glb.geo && this.glb.geo.geoType !== 'STATE' && !isSSR) {
                const data = {
                    url: window.location.pathname,
                    searchName: this.glb.seo.h1.replace('Real Estate & ', ''),
                };
                if (this.msp?.filter?.propertyType?.length === 0) {
                    data.searchName = this.glb?.geo?.formatAddress || data.searchName;
                } else if (this.msp?.filter?.propertyType?.length === 1) {
                    data.searchName = propertyTypeMap[this.msp?.filter?.propertyType[0]];
                } else if (this.msp?.filter?.propertyType?.length > 1) {
                    data.searchName = 'Real estate';
                }
                return data;
            } else if (this.glb.geo && this.glb.geo.cityPath && !['vschools_base', 'vschools_state'].includes(this.glb.pageType)) {
                return {
                    url: '/' + this.geo.cityPath,
                    searchName: `${this.geo.city} ${this.geo.state}`,
                };
            } else {
                return null;
            }
        },
        actionSaveHome() {
            const opt = JSON.parse($.getStorage('favoriteHome'));
            if (!opt) {
                return;
            }
            this.updateFavorite(opt).then((res) => {
                if (res.status && res.status.code === 0 && opt.isFavorite) {
                    this.$eventBus.$emit('dialog-center-tip', `<span class="text-bold">Success!</span> <span class="text-medium">Home saved.</span>`);
                }
                if (res.status && res.status.code === 0) {
                    $.setStorage('favoriteHome', '');
                }
            });
        },
        actionSaveSearch(opt) {
            let baseData = this.getBaseDataForSaveSearch();
            if (!baseData) {
                return;
            }
            let trigger = opt && opt.trigger;
            var data = {
                userId: this.glb.user.id,
                trigger: trigger || this.$options.name,
                userType: this.glb.pageInfo.userType,
                schoolRequest: this.getSchoolRequest,
                ...baseData,
            };
            let hasLogin = !!this.glb.user.id;
            this.addSaveSearch(data).then(() => {
                if (hasLogin) {
                    this.$eventBus.$emit('dialog-center', {
                        name: 'mvtSearchSaveConfirm',
                        opt: {
                            searchName: data.searchName,
                        },
                        trigger: trigger || this.$options.name,
                    });
                }
            });
        },
        handleDefaultAddSaveSearch(data) {
            let baseData = this.getBaseDataForSaveSearch();
            if (!baseData) {
                return;
            }
            let input = {
                userId: data && data.user ? data.user.id : '',
                trigger: this.$options.name || data.trigger,
                userType: this.glb.pageInfo.userType,
                schoolRequest: this.getSchoolRequest,
                ...baseData,
            };
            this.addSaveSearch(input);
        },
        saveSearch() {
            this.actionSaveSearch();
        },
        _show() {
            this.expand = true;
            $('#body').addClass('expand');
        },
        _hide() {
            this.expand = false;
            $('#body').removeClass('expand');
        },
        _toggle() {
            if ($('#body').hasClass('expand')) {
                this._hide();
            } else {
                this._show();
            }
        },
        goHome() {
            $.redirect(this.glb.appUrl);
        },
        displayPostReferralAgentForm() {
            if (!$.getStorage('isAgentFeedbackFormShown') && this.showAgentFeedbackPopup()) {
                this.$eventBus.$emit('dialog-center', {
                    name: 'agentFeedback',
                    opt: {
                        agentId: this.getAgent.id,
                        agentName: this.getAgent.name,
                        profileUrl: this.getAgent.profilePhotoUrl,
                    },
                    trigger: this.$options.name,
                });
                $.setStorage('isAgentFeedbackFormShown', true);
            }
        },
        showAgentFeedbackPopup() {
            if (this.getAgent && this.getAgent.relationshipCreatedAt && !this.getAgent.isAgentFeedback) {
                try {
                    let today = new Date();
                    let relationshipCreated = new Date(this.getAgent.relationshipCreatedAt);
                    let timeDiff = Math.abs(today - relationshipCreated);
                    let daysDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));
                    return daysDifference <= 7;
                } catch (err) {
                    return false;
                }
            }
            return false;
        },
        viewportUpdated() {
            this.updateViewport({
                width: $.winStatus.width,
                height: $.winStatus.height,
            });
        },
        initalLastSearchByStorage(opt) {
            let lastSearch = $.getStorage('lastSearch') || null;
            let prevSearch = $.getStorage('prevSearch') || null;
            var currentSearch = null;
            if (this.isMapSearchPage && !isSSR) {
                var path = opt ? opt.to.path : window.location.pathname;
                let saveSearch = getSaveSearchByPath(path);
                if (saveSearch) {
                    currentSearch = saveSearch;
                }
            }
            if (currentSearch && lastSearch) {
                if (currentSearch.displayText === lastSearch.displayText) {
                    if (this.isMapSearchPage && currentSearch.url.split('@')[0] !== lastSearch.url.split('@')[0]) {
                        this.updateLastSearchinfo(currentSearch);
                        $.setStorage('lastSearch', currentSearch);
                    } else {
                        if (!this.isNewSearch) {
                            return;
                        }
                        this.updateLastSearchinfo(lastSearch);
                        this.updatePrevSearchinfo(prevSearch);
                        $.setStorage('lastSearch', lastSearch);
                        $.setStorage('prevSearch', prevSearch);
                    }
                } else {
                    this.updateLastSearchinfo(currentSearch);
                    this.updatePrevSearchinfo(lastSearch);
                    $.setStorage('lastSearch', currentSearch);
                    $.setStorage('prevSearch', lastSearch);
                }
            } else if (currentSearch) {
                this.updateLastSearchinfo(currentSearch);
                this.updatePrevSearchinfo(currentSearch);
                $.setStorage('lastSearch', currentSearch);
                $.setStorage('prevSearch', currentSearch);
            } else if (lastSearch) {
                this.updateLastSearchinfo(lastSearch);
                $.setStorage('lastSearch', lastSearch);
            }
            if(currentSearch){
                currentSearch.text = currentSearch.displayText;
                currentSearch.displayText = this.msp.searchInput;
                this.updateSearchList(currentSearch);
            }
            this.isNewSearch = false;
        },
        getSearchUrlByGeo(geo, text, rentals) {
            return getPathBySearchObj(geo, { rentals: rentals });
        },
        showPopup(listings, options = { isMlsNumberSearch: false, searchText: '' }) {
            this.$eventBus.$emit('dialog-center', {
                name: 'mvtSearchResult',
                trigger: this.$options.name,
                opt: {
                    data: listings,
                    text: options.searchText || this.searchInfo.text,
                    isLink: true,
                    isMlsNumberSearch: options.isMlsNumberSearch,
                },
            });
        },
        popResult(result, options = { searchText: '' }) {
            var that = this;
            if (result.listings && result.listings.length) {
                if (result.listings.length === 1) {
                    let url = result.listings[0].dppUrl;
                    if (this.popLeadBeforeRedirect) {
                        this.$eventBus.$emit('eventbus-popLead-beforeRedirect', url);
                    } else {
                        $.redirect(url);
                    }
                } else {
                    that.showPopup(result.listings, {
                        searchText: options.searchText,
                        isMlsNumberSearch: !!(result.viewUrl && result.viewUrl.startsWith('mlsnumber-')),
                    });
                }
            } else {
                that.showPopup([], {searchText: options.searchText});
            }
        },
        async getHouseByPath(path, extraPath, includeAllAddress = false) {
            var that = this;
            let searchPayload = { path, trigger: this.$options.name };
            if (includeAllAddress) {
                searchPayload.includeAllAddress = true;
            }
            var result = await this.getHousesBySearchUrl(searchPayload);
            if (result.listings && result.listings.length) {
                result.listings = result.listings.map(function (e) {
                    var dpp = mapper.property(e, that.glb);
                    dpp.dppUrl += extraPath || '';
                    return dpp;
                });
            }
            return result;
        },
        async getHousesByGoogleGeo(searchInfo) {
            if (searchInfo && searchInfo.geo && searchInfo.geo) {
                let path = getPathBySearchObj(searchInfo.geo);
                var result = await this.getHouseByPath(path, '', true);
                return result ? result.listings : [];
            }
            return [];
        },
        doAddressSearch(searchInfo) {
            var that = this;
            if (searchInfo && searchInfo.geo) {
                let path = getPathBySearchObj(searchInfo.geo);
                const includeAllAddress = true;
                this.getHouseByPath(path, searchInfo.extraPath, includeAllAddress).then(function (result) {
                    if (searchInfo.ACForm || searchInfo.getClaimedHome) {
                        searchInfo.getFirstListing(result);
                    } else {
                        const geoInfo = JSON.parse(JSON.stringify(searchInfo));
                        const params = {
                            lat: geoInfo.geo.geometry.location.lat,
                            lng: geoInfo.geo.geometry.location.lng,
                            rentals: !!that.glb.rentals,
                            count: 10,
                        };
                        if (!result.listings) {
                            that.fetchNearbyHomesByLatLng(params).then(function (res) {
                                if (that.getNearbyHomesFromLatLng && that.getNearbyHomesFromLatLng.length) {
                                    const nearbyList = {
                                        listings: that.getNearbyHomesFromLatLng,
                                    };
                                    that.popResult(nearbyList, {searchText: searchInfo.text});
                                }
                            });
                        } else {
                            that.popResult(result, {searchText: searchInfo.text});
                        }
                    }
                });
            }
        },
        doMlsNUmber(searchInfo) {
            var that = this;
            var path = `/mlsnumber-${searchInfo.text}/`;
            this.getHouseByPath(path).then(function (result) {
                that.popResult(result, {searchText: searchInfo.text});
            });
        },
        doGoogleGeoSearch(searchInfo) {
            if (searchInfo.geo && searchInfo.geo.address_components) {
                var hasRedirect = false;
                var googleTypes = ['locality', 'neighborhood', 'county', 'postal_code'];
                var isGoogleGeo = searchInfo.geo.address_components.reduce(function (prev, next) {
                    if (prev) {
                        return prev;
                    } else if (next.types.indexOf('street_number') >= 0) {
                        return true;
                    } else if (next.types.some((v) => googleTypes.indexOf(v) >= 0)) {
                        hasRedirect = true;
                        return true;
                    } else {
                        return false;
                    }
                }, false);
                let path;
                if (!isGoogleGeo) {
                    if (!searchInfo.text) {
                        return;
                    }
                    //change all characters not a-z or 0-9 to '-'
                    path = `address-${searchInfo.text.replace(/[^a-z0-9]/gi, '-')}/`;
                    //remove all '-' at the beginning and end of the string and remove duplicate '-'
                    path = path.replace(/^-+|-+$/g, '').replace(/-+/g, '-');
                }
                if (!path) {
                    path = this.getSearchUrlByGeo(searchInfo.geo, searchInfo.text, this.glb.rentals);
                }
                if (hasRedirect) {
                    $.redirect(this.glb.appUrl + path);
                    return;
                }
                return this.getHouseByPath(path, '', true).then((result) => {
                    this.popResult(result, { searchText: searchInfo.text });
                });
            } else {
                this.showPopup([], {searchText: searchInfo.text});
            }
            return this.doGeoSearch(searchInfo);
        },
        doGeoSearch(searchInfo) {
            var that = this;
            if (!searchInfo || !searchInfo.text || !searchInfo.geo) {
                return;
            }

            if (this.isMapSearchPage) {
                var params = {
                    searchText: searchInfo.text,
                    searchSuggestion: searchInfo.geo,
                    extraPath: searchInfo.extraPath,
                };
                that.$eventBus.$emit('eventbus-text-search', params);
            } else if (searchInfo.geo) {
                if (searchInfo.extraPath) {
                    searchInfo.geo.extraPath = searchInfo.extraPath;
                }
                var geoInfo = mapper.geo(searchInfo.geo, this.glb.rentals);
                var path = this.glb.appUrl + geoInfo.geoPath;
                if (searchInfo.geo.type === 'school' && searchInfo.geo.schoolId) {
                    const path = getPathBySearchObj(searchInfo.geo, { rentals: this.glb.rentals, schoolId: searchInfo.geo.schoolId });
                    $.redirect(this.glb.appUrl + path);
                } else if (searchInfo.extraPath) {
                    $.redirect(path + searchInfo.extraPath);
                } else if (geoInfo.geoType === 'CITY') {
                    $.redirect(`${path}@${geoInfo.lat},${geoInfo.lng}/`);
                } else {
                    $.redirect(path);
                }
            }
        },
        showLogin: function (tigger) {
            this.$eventBus.$emit('dialog-center', { name: 'mvtAuthenticate', opt: { type: 'mvtEmailLogin' }, trigger: tigger || this.$options.name });
        },
        showSignup: function (tigger, lite, requestName, options, propertyData) {
            var opt = { type: 'mvtLogin', requestName: requestName };
            if (options) {
                options.title && (opt.title = options.title);
                options.subTitle && (opt.subTitle = options.subTitle);
            }
            if (lite) {
                opt.type = 'mvtCheckEmail';
                propertyData && (opt.propertyData = propertyData);
                if (options) {
                    options.buttonText && (opt.buttonText = options.buttonText);
                }
            }
            this.$eventBus.$emit('dialog-center', { name: 'mvtAuthenticate', opt, trigger: tigger || this.$options.name });
        },
        signout: function () {
            const url = `${this.glb.appUrl}logout/`;
            this.$eventBus.$emit('eventbus-context-update');
            $.removeStorage('googleSignInPopCloseTime');
            $.setStorage('holeadInfo', null);
            $.setStorage('hotleadSentPropertyList', []);
            $.setStorage('googleRedirectUrl', '');
            $.setStorage('autoPopAfterAuthenticated', false);
            $.setStorage('hotleadCount', 0);
            setTimeout(function () {
                $.redirect(url);
            }, 2000);
        },
        sendEvent: function (name) {
            this.$eventBus.$emit(name);
        },
        checkNotification() {
            var records = $.getStorage('saveSearchRecord');
            this.isNotification = $.notification.allowed() && records && records.length > 0;
        },

        search(newVal) {
            if (newVal.isMlsNumber) {
                return this.doMlsNUmber(newVal);
            }
            if (newVal.isGoogleGeo) {
                this.updateSearchList(newVal);
                return this.doGoogleGeoSearch(newVal);
            }
            if (newVal.geo) {
                return this.doGeoSearch(newVal);
            }
        },
        updateSearchList(newVal)  {
            if (!isSSR) {
                if  (newVal)  {
                    this.updateRecentSearchList(newVal);
                }
            }
        },
        goFirst(item) {
            if (item.href) {
                $.redirect(item.href);
                return;
            }
            let list = item.list;
            if (list && list.length && list[0].href) {
                $.redirect(list[0].href);
            }
        },
        goSurvey({ text, geo, type }) {
            if (type) {
                this.getHousesByGoogleGeo({ text, geo }).then((res) => {
                    if (res && res.length > 0) {
                        var geoInfo = res[0] && res[0].geo;
                        if (geoInfo.zipcode) {
                            $.redirect(`${this.glb.appUrl}survey/${type}/?zipcode=${geoInfo.zipcode}`);
                            return;
                        }
                    }
                    this.showPopup([], {searchText: text});
                });
            }
        },
        popInsurence(searchInfo) {
            this.$eventBus.$emit('ga', { en: 'insurance_session', ec: 'partner', el: 'insurify', props: { link_name: 'getquotes_insurancepage' } });
            window.open('https://insurify.com/start/home/partner/ojo/?utm_source=ojo&utm_medium=home&utm_campaign=insurancepage', '_blank');
        },
        openHotlead() {
            this.$eventBus.$emit('dialog-center', {
                name: 'mvtHotleadForm',
                trigger: this.$options.name,
                opt: {
                    hotleadTitle: this.molang('header.HotleadTitle'),
                    hotleadSub: this.molang('header.HotleadPara'),
                    hotleadType: 'ExpertConsultConsumerGlobalHomePageBottom',
                    buttonText: this.molang('header.HotleadButtionCap'),
                    showZipcode: true,
                },
            });
        },
        getClaimedHomeAddress(geoInfo) {
            this.doAddressSearch({
                text: geoInfo.text,
                geo: geoInfo.geo,
                getClaimedHome: true,
                getFirstListing: this.getFirstListing,
            });
        },
        getFirstListing(data) {
            var that = this;
            let campaign = 'homeowner-signup';
            if (this.glb.pageType.includes('vhomeservices')) {
                campaign = 'maintenance-signup';
            }
            if (data && data.listings && data.listings.length) {
                var listing = data.listings[0];
                var address = (listing.geo && listing.geo.address) || '';
                var options = {
                    propertyId: listing.propertyId,
                    isPrOnly: !listing.listingId,
                    campaign: campaign,
                    dppPath: listing.path,
                    authOptions: {
                        subTitle: `Where would you like to receive updates about ${address}?`,
                        buttonText: 'See My Dashboard',
                    },
                    propertyData: { tnImgPath: listing.tnImgPath, photoCount: listing.photoCount, geo: listing.geo },
                };
                this.tempClaimedHomes = [listing];
                this.addClaimedHome(options).then(function (res) {
                    if (res) {
                        that.$eventBus.$emit('eventbus-add-claimedhome');
                        that.$eventBus.$emit('eventbus-reset-inputgeo');
                        setTimeout(function () {
                            that.$eventBus.$emit('dialog-center', {
                                name: 'mvtSellLead',
                                trigger: that.$options.name,
                                opt: {
                                    hotleadType: 'PostClaimHomeAlsoSelling',
                                    hotleadTitle: `Selling ${listing.geo.address}?`,
                                    propertyId: listing.propertyId,
                                    propertyData: listing,
                                    showThank: that.glb.user && that.glb.user.id,
                                    showDisclaimer: false,
                                    showSellLeadForm: true,
                                },
                            });
                        }, 10);
                    }
                    that.$eventBus.$emit('eventbus-dialog-close');
                });
                this.$eventBus.$once('eventbus-dialog-close', function () {
                    if (!that.glb.user.id) that.tempClaimedHomes = null;
                });
            } else {
                this.tempClaimedHomes = null;
                this.$eventBus.$emit('dialog-center', { name: 'mvtGeoSearchResult', trigger: this.$options.name, opt: {} });
            }
        },
        openToast() {
            this.$eventBus.$emit('toast-linked', {
                message: 'Save this property to get updates on status, price changes, and open houses.',
                iconClass: 'icon-heart-o',
                title: 'Love this home?',
                loc: 'right',
                autoclose: true,
                scrollClose: this.getSplit('movoto-dpp-redesign-CW-2515'),
            });
            $.setStorage('isToastDisplayed', true);
        }
    },
    beforeMount() {
        var that = this;
        this.$eventBus.$on('eventbus-context-update', function (data) {
            $.updateContext();
        });
        this.$eventBus.$on('eventbus-signout', this.signout);
        this.$eventBus.$on('eventbus-page-update', this.initalLastSearchByStorage);
        this.$eventBus.$on('eventbus-login', ({ trigger }) => {
            that.showLogin(trigger);
        });
        this.$eventBus.$on('eventbus-search', (searchInfo) => {
            that.search(searchInfo);
        });
        this.$eventBus.$on('eventbus-search-address', this.doAddressSearch);
        this.$eventBus.$on('eventbus-signup', ({ trigger, lite, requestName, options, propertyData }) => {
            that.showSignup(trigger, lite, requestName, options, propertyData);
        });
        this.$eventBus.$on('eventbus-survey', this.goSurvey);
        this.$eventBus.$on('eventbus-pop-insurance', this.popInsurence);
        this.$eventBus.$on('eventbus-claimed-home', this.getClaimedHomeAddress);
        this.$eventBus.$on('eventbus-save-search-default', this.handleDefaultAddSaveSearch);
        this.$eventBus.$on('eventbus-save-search', this.actionSaveSearch);
        this.$eventBus.$on('eventbus-save-home', this.actionSaveHome);
        if (!isSSR) {
            if (this.getSplit('movoto-cw-878-linter')) {
                $.setCookie('linter', 'on');
            } else if (this.getSplit('movoto-cw-878-linter', 'off')) {
                $.setCookie('linter', 'off');
            }
            if (window.location.search.indexOf('linter=') >= 0) {
                this.mvtLinter = defineAsyncComponent(() => import(/* webpackChunkName: "mvtlinter" */ '@/common/components/mvtlinter/mvt-linter.vue'))
            }
        }
    },
    mounted: function () {
        this.isMounted = true;
        this.renderPersonalizedCounts = true;
        var $this = $(document.getElementById('header'));
        var $list = $this.find('.header-menu-list');
        var $dropdown = $list.find('.list');
        var $overlay = $('<div class="header-overlay"></div>');
        var that = this;
        $this.prepend($overlay);
        var isToastDisplayed = $.getStorage('isToastDisplayed');
        if (!isToastDisplayed) {
            var toastTime = 3 * 60 * 1000;
            if (!$.getStorage('sessionStart')) {
                var currentTime = new Date();
                $.setStorage('sessionStart', currentTime.getTime());
            } else {
                var nowDate = new Date();
                var timeDiff = nowDate.getTime() - $.getStorage('sessionStart');
                if (timeDiff < toastTime) {
                    toastTime = toastTime - timeDiff;
                }
            }

            setTimeout(function () {
                if (!that.getSavedHomesCount && that.glb.pageType === 'vdpp' && !$.getStorage('isToastDisplayed')) {
                    that.openToast();
                }
            }, toastTime);
        }
        // setTimeout(function () {
        //     that.openToast();
        // }, 1000);
        $overlay.on('click', this._hide);
        $dropdown.each(function () {
            var $arrow = $('<a href="javascript:;" class="header-expand" role="button" aria-label="Expand List"><i class="icon-angle-down"></i></a>');
            $arrow.on('click', function () {
                var $li = $(this).closest('li');
                var $prev = $li.siblings('.hover');
                $prev.removeClass('hover');
                $prev.css('height', '');
                if ($li.hasClass('hover')) {
                    $li.removeClass('hover');
                    $li.css('height', '');
                } else {
                    $li.addClass('hover');
                    $li.css('height', $li.prop('scrollHeight') + 1);
                }
            });
            $(this).append($arrow);
        });
        $(document).on('dom.resize.' + this.vnumber, function () {
            var $openItem = $list.find('.list.hover');
            $openItem.removeClass('hover');
            $openItem.css('height', '');
            that._hide();
            that.viewportUpdated();
        });
        this.$refs.autocomplete && this.$refs.autocomplete.update(this.getSearchInput);
        if (this.glb.user.id) {
            this.getSavedSearches();
            this.getClaimedHomes();
            this.getFavoriteListings();
            this.displayPostReferralAgentForm();
        }
        if (!this.glb.initialed) {
            this.glb.initialed = true;
            this.$eventBus.$emit('eventbus-page-update');
        }
        this.checkNotification();

        if ($.getStorage('isGoogleLogin')) {
            const pendingRequest = $.getStorage('pendingRequestName');
            if (this.glb.user.email && pendingRequest) {
                switch (pendingRequest) {
                    case 'savedsearch':
                        this.$eventBus.$emit('eventbus-save-search', { trigger: this.$options.name });
                        break;
                    case 'addfavorite':
                        this.$eventBus.$emit('eventbus-save-home');
                        break;
                }
                $.removeStorage('pendingRequestName');
                $.removeStorage('isGoogleLogin');
            }
        }
    },
    data: function () {
        return {
            mvtLinter: null,
            expand: false,
            autocomplete: '',
            searchInfo: {
                text: '',
                geo: null,
                extraPath: '',
            },
            isNewSearch: true,
            isNotification: false,
            renderPersonalizedCounts: false,
            isMounted: false,
        };
    },
    watch: {
        searchInfo(newVal, oldVal) {
            if (newVal) {
                this.search(newVal);
            }
        },
        getSearchInput: function (newVal, oldVal) {
            this.$refs.autocomplete.update(newVal);
        },
    },
    beforeUnmount() {
        this.$eventBus.$off('eventbus-search');
        // this.$eventBus.$off('eventbus-page-update');
        this.$eventBus.$off('eventbus-login');
        this.$eventBus.$off('eventbus-signup');
        this.$eventBus.$off('eventbus-claimed-home');
        this.$eventBus.$off('call-savesearch');
        this.$eventBus.$off('eventbus-save-search-default');
        this.$eventBus.$off('eventbus-save-search');
        $(document).off('dom.resize.' + this.vnumber);
    },
};