import mvtTextbox from '@/common/components/mvttextbox/mvt-textbox.vue';
import mvtSocial from '@/common/components/mvtsocial/mvt-social.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'mvtCheckEmail',
    inject: ['$eventBus'],
    props: {
        options: {
            type: Object,
            default: {
                title: null,
                buttonText: null,
                trigger: '',
                subTitle: null,
                icon: '',
                hideProfile: false,
                propertyData: null,
            },
        },
    },
    components: {
        mvtTextbox,
        mvtSocial,
    },
    data() {
        return {
            email: '',
        };
    },
    mounted() {
        if (!this.glb.user.id) {
            $.setStorage('pendingRequestName', this.options.requestName);
        }
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getSplit']),
        profile() {
            if (!this.options.hideProfile) {
                if (this.formInfo.imgUrl) {
                    return this.formInfo.imgUrl;
                } else if (this.options.propertyData) {
                    if (this.options.propertyData.photoCount > 0) {
                        return this.options.propertyData.tnImgPath;
                    } else if (this.options.propertyData.geo) {
                        var key = encodeURIComponent(this.options.propertyData.geo.formatAddress || this.options.propertyData.geo.lat + '_' + this.options.propertyData.geo.lng) + '_streetview';
                        return $.getHashValue(key) || this.options.propertyData.tnImgPath;
                    }
                }
                if (this.formInfo.icon) {
                    return this.formInfo.icon;
                }
            }
            return null;
        },
        formInfo() {
            var formInfo = {
                title: 'Where should we send this to?',
                buttonText: 'Continue',
                subTitle: '',
                icon: '',
                showDisclaimer: false,
            };

            if (this.isSaveSearch) {
                formInfo.title = 'Save This Search';
                formInfo.icon = 'icon-bookmark-o';
                formInfo.subTitle = 'Enter your email and we’ll notify you when homes that match this search hit the market.';
                formInfo.buttonText = 'Save This Search';
            } else if (this.options.trigger === 'notification') {
                formInfo.buttonText = 'Get Updates';
                formInfo.title = '';
                formInfo.subTitle = 'Create your free account to receive new home updates in ' + this.glb.geo.zipcode + '.';
            } else if (this.options.trigger === 'digsScubscription') {
                formInfo.buttonText = 'Claim Home';
                formInfo.title = 'Own this home?';
                formInfo.subTitle = "Get emailed updates to your home's value";
                formInfo.showDisclaimer = true;
            } else if (this.isSaveProperty) {
                formInfo.title = 'Save This Home';
                formInfo.subTitle = 'Interested in this home? Get emailed when updates are made.';
                formInfo.buttonText = 'Save Home';
                formInfo.icon = 'icon-heart-o';
            } else if (this.isClaimHome || this.isSimpleDppClaim) {
                formInfo.title = `Track Your Home's Value`;
                formInfo.subTitle = `<div class="m-t-2 flex vertical fit-content text-left"><div class="flex left middle"><i class="f2 text-green icon-check-circle-outside"></i> Uncover Hidden Savings</div><div class="flex left middle"><i class="f2 text-green icon-check-circle-outside"></i> View Neighborhood Sales & Trends</div><div class="flex left middle"><i class="f2 text-green icon-check-circle-outside"></i> Get Maintenance Help</div></div>`;
                formInfo.buttonText = 'Add Home';
                formInfo.imgUrl = this.options.propertyData && this.options.propertyData.tnImgPath ? this.options.propertyData.tnImgPath : this.glb.cdnUrl + 'vimages/claimhome.png';
            }

            if (this.options.icon) {
                formInfo.icon = this.options.icon;
            }
            if (this.options.title) {
                formInfo.title = this.options.title;
            }
            if (this.options.buttonText) {
                formInfo.buttonText = this.options.buttonText;
            }
            if (this.options.subTitle) {
                formInfo.subTitle = this.options.subTitle;
            }
            if (this.options.propertyData) {
                formInfo.propertyData = this.options.propertyData;
            }
            return formInfo;
        },
        isTriggerByAuth() {
            return this.options.trigger !== 'mvtHeader' && this.options.trigger !== 'mvtFooter';
        },
        isSaveSearch() {
            return this.options.requestName === 'savedsearch';
        },
        isSaveProperty() {
            return this.options.requestName === 'addfavorite';
        },
        isClaimHome() {
            return this.options.requestName === 'addclaimhome';
        },
        isSimpleDppClaim() {
            return this.options.requestName === 'simpledppclaim';
        },
    },
    methods: {
        ...mapActions('glb', ['login', 'checkUserByEmailId']),
        ...mapMutations('glb', ['updateUserInfo']),
        switchComponent(name) {
            this.$emit('switchComponent', name);
        },
        submitSignup() {
            this.$eventBus.$emit('eventbus-submit-signup', { email: this.email, requestName: this.options.requestName || '' });
        },
    },
};
