<template>
    <div class="dialog-loading"></div>
</template>
<script>
export default {
    name: 'loadingDialog',
};
</script>
<style>
.loadingDialog {
    .dialog-loading {
        margin: 0 auto;
        width: 15px;
        aspect-ratio: 1;
        border-radius: 50%;
        animation: green-circle-loader 1s infinite linear alternate;
    }
    .dialog-close {
        display: none;
    }

    @keyframes green-circle-loader {
        0%  {box-shadow: 20px 0 var(--color-main), -20px 0 var(--color-main-l1);background: var(--color-main) }
        33% {box-shadow: 20px 0 var(--color-main), -20px 0 var(--color-main-l1);background: var(--color-main-l1)}
        66% {box-shadow: 20px 0 var(--color-main-l1),-20px 0 var(--color-main); background: var(--color-main-l1)}
        100%{box-shadow: 20px 0 var(--color-main-l1),-20px 0 var(--color-main); background: var(--color-main) }
    }
}
</style>